import { BannerList, EContainerVariant, toImageProps } from '@front/shared/ds';
import { Section } from '@shared/master-types';
import { EBannerHide } from 'front/shared/ds/src/components/rows/BannerList/BannerList.constants';
import React from 'react';

export type TBannerListConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-list' }
>;

const BannerListConnected: React.FC<TBannerListConnectedProps> = props => {
  const { bannerList = [], containerVariant } = props;

  const containerVariantConnect =
    containerVariant === EContainerVariant.Default
      ? EContainerVariant.Default
      : EContainerVariant.Lead;

  return (
    <BannerList
      bannerList={bannerList.map(item => ({
        banner: toImageProps(item.banner),
        hide:
          item.hide === EBannerHide.Never
            ? EBannerHide.Never
            : item.hide === EBannerHide.Tablet
              ? EBannerHide.Tablet
              : EBannerHide.Mobile,
      }))}
      containerVariant={containerVariantConnect}
    />
  );
};

export default BannerListConnected;
